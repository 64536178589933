import React from "react"
import s from 'styled-components'

import Layout from "../components/layout"
import { Text, TextSubheading, TextWrapperOne } from "../data/typography"

const BaljachwiPage = () => (
  <Layout pageTitle={'중 앙 종 문 회'} pageSubtitle={'발자취'}>
    <TextWrapperOne>
      <Text>
        <TextSubheading>1. 재경(在京)고씨회 활동</TextSubheading>

        1) 전국 각 지역에 흩어져 살고 있는 고씨들을 조직화하여 단체를 설립해야 한다는 필요성과 주장이 열화와 같았으나 구심점이 없어 미뤄오다가 1956년 7월 21일 서울의 덕수궁에서 서울을 중심으로 한 고씨들이 모여 가칭 『재경고씨회』를 창립하는 역사적인 쾌거를

        이룩하였다.<br /><br />


        2) 특히, 1950년 6.25 동란을 겪으면서 전국에 산재한 종친들이 조직적으로 단합하며 서로 돕고 연계해야 더 큰 문중으로 발전시킬 수 있다는 염원이 비로소 그 결실을 맺게 된 것이다.<br /><br />

        3) 덕수궁에서 개최한 재경고씨회 창립총회에서 초대회장에 髙義東

        님, 부회장에 髙在鎬, 髙 雄님을 선출하여 체계적이고 조직화된 종문회 활동을 시작하였다.<br />

        다음해인 1957년 5월 12일 서울의 장춘단공원에서 개최한 제2회 정기총회에서 임원을 개선하였는데, 회장은 유임하고, 부회장은 髙在鎬, 髙白圭님을 선출하였다.<br />

        1958년 10월 12일 제4회 정기총회에서 종문회의 명칭을 『재경고씨 종문회』로 변경하였다.<br />

        1959년 10월 11일 서울의 덕수궁에서 개최한 제5회 정기총회에서 임원을 개선하였는데 회장에 髙漢圭,부회장에 髙漢淑, 髙判南, 髙興門

        님이 선출되었다.<br />

        1963년 6월16일 서울의 덕수궁에서 제7회 정기총회를 개최하여 임원을 개선하였는데 회장에 髙秉幹, 부회장에 髙在鎬, 髙元增, 髙鎭永

        님을 선출하였다.<br />

        1966년 6월5일 서울의 종묘에서 제10회 정기총회를 개최하여 부회장 중 髙鎭永 종친이 퇴임하고 髙錫健님을 선출하였다.<br />

        1967년 10월 24일 서울의 덕수궁에서 제11회 정기총회를 개최하여 임원을 개선하였는데, 회장에 髙在旭, 부회장에 髙 雄, 髙元植, 髙在珌, 髙興門님을 선출하였고 종문회의 명칭을 『고씨종문회』로 개정하였다.<br /><br />

        4) 1970년 5월 27일 髙興門 부회장이 특별찬조금으로 55만원을 희사하여 서울특별시 남대문로 5가 한성빌딩 306호에 비로소 단독 사무실을 갖게 되었다.<br />

        1971년 덕수궁에서 제13회 정기총회를 개최하여 부회장에 髙京善님을 추가로 선출하였다.<br /><br />

        5) 1971년 3월 13일 『髙氏世錄編纂委員會)』를 조직하여 髙在旭 회장이 위원장직을 맡아 1973년 2월 20일 『髙氏世錄』을 발간하여 전국 종친들에게 배포하였다.<br /><br />

        <TextSubheading>2. 고씨중앙종문회의 창립</TextSubheading>

        1) 1973년 12월 15일 서울의 북창동 소재 한국회관에서 200여명의 국내외 종친들이 참석한 가운데 창립총회를 개최하고 종문회의 명칭을 현재의 『고씨중앙종문회』로 결정하였다.<br />

        이날 총회에서 초대회장에 髙在旭, 상입부회장에 髙京善, 부회장에 (서울)髙 雄, (제주)髙禎孝, (군산)髙判南, (부산) 髙斗範,

        (광주) 髙在珣, (대전) 髙永熙, (대구) 髙萬石, (동경) 髙漢俊 종친

        등을 선출하였으며 일본 재일교포를 포함한 전국 및 국외 동포를 포괄한 규모의 조직으로 확대 개편하였다.<br /><br />

        2) 제주도에는 선조의 발상지로서 선조의 유적 보존관리와 제향 등 의례적 행사를 관장하기 위하여 『총본부』로 하고, 전국 각 지역에도 지역 종문회의 명침을 『고씨 00종문회』로 통일하기로 결의하였다.<br />

        특히,『髙氏大同譜』를 편찬하기로 하고 위원회를 조직하여 각 파별로 40여 종친을 위원으로 선임하여 발간 작업을 하였다.<br /><br />

        3) 1976년 6월 22일 髙在旭 초대회장이 노환으로 타계하여 동년 11월 6일 서울의 명동 삼오정에서 임시총회를 개최하여 2대 회장에

        髙 雄, 상임부회장에髙義一, 부회장에 髙時百님 등 8명을 선임하였다.<br /><br />

        역대 회장 명단은 다음과 같다.<br />

        초대회장 髙在旭<br />

        2대회장 髙 雄<br />

        3대회장 髙興門<br />

        4대회장 髙亨坤<br />

        5~7대회장 髙在珌<br />

        8~10대회장 髙判南<br />

        11~12대회장 髙明哲<br />

        13~19대회장 髙濟哲<br />

        20~21대회장 髙鳳林<br />

        22대 회장 髙南化<br />

        23대~ 현회장 髙在鉀<br /><br />

        4) 1980년 6월27일 서울 여성회관에서 개최한 제7회 정기총회에서 재4대 회장으로 髙亨坤님을 선출하였는데 이날 총회에는 『회관건립추진위원회』와 『고씨종보』 발간을 결의 하는 등 고씨 문중의 발전에 획기적인 중요한 결정을 하였다.<br /><br />

        5) 1980년 6월27일 髙興門 3대 회장이 사임하면서 『서울종문회』는 해산하고 『중앙종문회』에 흡수 할 것을 결의하였다.<br /><br />

        6)1980년 7월 10일 광주광역시 髙永斗 종친이 회관건립기금으로 壹千七拾萬원(현물 쌀 300석)을 찬조하여 오늘의 회관을 건립하는데

        초석이 되었다.<br /><br />

        7) 1997년 3월 5일髙 建 국무총리 취임(중시조 이후 최초)<br /><br />

        8) 2020년 3월 현재의 우리나라 고씨 지역 조직 현황은 서울. 제주도. 중국 길림성 연변조선족 자치주를 비롯하여 100여개 지역에 종문회가 조직되어 운영되고 있으며 전 세계에 살고 있는 우리 고씨 인구는 2020년 3월 현재 약 10여만이 넘는 가구에서 약 60여만 여명에 이르고 있으며 전국의 성씨 인구 순위로는 22위에 해당 된다.<br /><br />

        9) 고씨중앙종문회관 : 중곡동 시대 개막<br />

        고씨중앙종문회는 서울시 용산구 용산동5가 1-13번지의 용산종문회관 (1990년7월~2008년 9월) 시대를 마감하고 현재의 고씨중앙종문회관(서울특별시 광진구 천호대로 617) 시대를 맞이하였다.<br />

        1990년9월9일 16:00중앙종문회관에서 입주 축원식을 지내며 중곡동 시대의 개막을 알렸다.<br />

        현재의 고씨중앙종문회관 현황은 다음과 같다.<br />

        ◎ 면적 – 토지 112평, 건물311평(현대식 7층 건물)<br />

        ◎ 준공일자 – 2004. 11. 10<br />

        ◎ 위치 – 서울시 광진구 천호대로 617<br />

        (자동차 이용 시 천호대로에서 면접하여 접근성이 용이하고 지하철 이용 시 5호선과 7호선의 교차점으로 편리한 위치에 소재하고 있다.)<br /><br />

        10) 고제철 제13대~19대 회장은 현재의 고씨중앙종문회관을 마련하는데 헌신하였고 4억여 원의 장학기금을 출현하여 현재 15억 원 규모의 장학기금을 조성하는데 기여하였으며 제주도 『탐라국종묘건립헌성금』으로 2억 2백만 원을 기탁하는 등 종문회 발전에 물심양면으로 협조하였다.<br /><br />

        11) 제22대 고남화 회장은 1억 원을 쾌척하여 재임기간 동안 종문회를 활성화 시키고 도약 할 수 있는 기틀을 마련하였다.<br /><br />

        12) 제23대 고재갑 현 회장은 우리 고씨가 탐라국가를 창건하여 5천여년간 계승 발전 시켜온 명문가문임을 명심하여 종문회가 『창립의 시대』 와 『중흥의 시대』를 맞이하게 되었음을 인식하고 세계적인 강소명문 종문회로 탈바꿈하기 위하여 세계적인 조직, 강력한 조직,

        미래시대의 가문변화에 선제적으로 대응하면서 끊임없이 발전하며 도약하는 가문으로 만드는데 중. 장기 계획을 수립하여 동시 다발적으로 운영하고 있다.<br /><br />

        <TextSubheading>3. 재단법인 고씨중앙종문장학회</TextSubheading>

        1) 재단법인 고씨중앙종문장학회는 1994년 1월 5일 발기인 총회를 거쳐 동년 6월 7일 제1-480호(서울특별시교육감)재단법인으로 설립 등기하였으며 고씨중앙종문회 회장을 이사장으로 이사와 감사를 선임하여 이사회에서 관리 운영하고 있다.<br /><br />

        2) 장학기금으로 고제철 중앙회장이 4억 원을 출현하는 등 전국적으로 300여명의 종친들이 장학기금을 출현하여 현재 15억 원의 기금을

        조성하였다.<br /><br />

        3) 장학금 지급 현황은 1995년 제1차로 대학생 7명과 고등학생 13명에게 16,800,000만원의 장학금을 지급한 것을 시작으로 2020년 3월 현재 대학생 406명과 고등학생 413명 등 821명의 고씨고등학생 및 대학생들에게 총계 금액 9억4천5백사십만 원의 장학금을 지급하였다.<br /><br />

        <TextSubheading>4. 고씨종보 발간</TextSubheading>

        1) 1980년 6월 27일 제7회 정기총회(회장 高亨坤)에서 고씨종보<br />

        (髙氏宗報) 발간을 결의 하였다. (※ 신문지 4절 판형)<br />

        1981년 3월 28일 문공부로부터 정기간행물 등록 마650호로 등록을 마치고 1981년 4월 30일 고씨종보 창간호10,000부를 발간하였다.<br />

        매호 발간부수는 20,000부(격월간 발행)이며 2020년 3월 1일 현재 124호를 발간하여 오늘에 이르고 있다.<br /><br />

        2) 고씨종보 발간을 활성화하기 위하여 2011년부터 편집위원회를 구성하여 고찬옥 고씨중앙종문회 부회장이 편집위원회를 이끌고 있으며 발행인은 고재갑 고씨중앙종문회장이 맡고 있다.
      </Text>
    </TextWrapperOne>
  </Layout>
)

export default BaljachwiPage
